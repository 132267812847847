import React from "react"
import Layout from "../../../components/layout"
import EfemeridesM from "../../../components/efemeridesm"
import CommentsFB from "../../../components/Comments"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const EfeEnero = () => (
  <Layout>
    <GatsbySeo
      title="Efemérides - Mayo"
      titleTemplate="%s | Eduardo Ceballos"
      description="Efemérides de Salta por Eduardo Ceballos"
      canonical="https://eduardoceballos.com/libros/efemerides/mayo"
      openGraph={{
        url: "https://eduardoceballos.com/libros/efemerides/mayo",
        title: "Efemérides - Mayo | Eduardo Ceballos",
        description: "Efemérides de Salta por Eduardo Ceballos",
        images: [
          {
            url: `https://res.cloudinary.com/lagexpress/image/upload/v1597476577/strapi/tapalibroconozcasaltaatravesdesusefemerides_4670275357.jpg`,
            alt:
              "Tapa del libro del libro Conozca Salta a través de sus Efemérides",
          },
        ],
        site_name: "Eduardo Ceballos",
      }}
      twitter={{
        handle: "@PoetadeSalta",
        site: "@PoetadeSalta",
        cardType: "summary_large_image",
      }}
    />
    <h1>Conozca Salta a través de sus Efemérides – MAYO</h1>
    <hr />
    <EfemeridesM />
    <hr />
    <p>
      1 de mayo de 1814:&nbsp;El capitán Luis Burela, del ejército de Güemes,
      derrotó a los realistas mandados por el capitán Ezenarro, en Chicoana,
      provincia de Salta.
    </p>
    <p>
      1 de mayo de 1817:&nbsp;El capitán Mariano Adonaegui, del ejército de
      Güemes, derrotó a los realistas, en quinta de Arias, provincia de Salta.
    </p>
    <p>
      1 de mayo de 1853:&nbsp;Fue sancionada por el Congreso General
      Constituyente, reunido en Santa Fe, la Constitución Nacional, siendo
      presidente de la Confederación Argentina, en forma interina, el general
      Justo José de Urquiza. Fue sancioada por 24 diputados de 13 provincias. En
      representación de Salta, el diputado Facundo Zuviría.
    </p>
    <p>
      1 de mayo de 1890:&nbsp;Asumió como gobernador de Salta, el doctor Pedro
      J. Frías.
    </p>
    <p>
      1 de mayo de 1893:&nbsp;Asumió como gobernador de Salta, el doctor Delfín
      Leguizamón.
    </p>
    <p>
      1 de mayo de 1925:&nbsp;Asumió como gobernador titular de Salta, el doctor
      Joaquín Corbalán.
    </p>
    <p>
      1 de mayo de 1928:&nbsp;Asumió el gobierno de Salta, el doctor Julio
      Cornejo.
    </p>
    <p>
      1 de mayo de 1932:&nbsp;Nació en El Barrial, departamento de San Carlos,
      provincia de Salta, Gerardo López. Fue uno de los fundadores de Los
      Fronterizos, que en su origen era un Trío integrdo por López, Carlos
      Barbarán y Solá. Solá fue reemplazado por Valdez y a este Eduardo Madeo.
      Gerardo López se casó con Isabel Alvarado en 1959, siendo padrino de la
      pareja Horacio Guarany. Luego de la exitosa carrera artística de Los
      Fronterizos, López formó su propio grupo, Las Voces de Gerardo López, que
      siguen cantando por el país.
    </p>
    <p>
      1 de mayo de 1936:&nbsp;Asumió la gobernación de Salta, Luis Patrón
      Costas, elegido por el pueblo.
    </p>
    <p>
      1 de mayo de 1946:&nbsp;Nació en Chicoana, provincia de Salta, Domingo
      Gatti. Piloto Civil, que se jubiló prestando servicio en la provincia.
      Egresó de la Escuela de Bellas Artes. Se especializó como dibujante en
      pluma y tinta, sus temas principales son los edificios y casas históricas
      de la ciudad y pueblos del interior, como un mensaje de preservación del
      patrimonio histórico.
    </p>
    <p>
      1 de mayo de 1955: Nació en Salta, David Antonio Sorich. Docente,
      escritor, investigador. Es autor de teatro, fábulas poéticas. Preside la
      Comisión Interprovincial Permanente de Homenaje a Lola Mora.
    </p>
    <p>
      1 de mayo de 1958:&nbsp;Habiendo triunfado el doctor Arturo Frondizi, en
      la provincia de Salta asumió la gobernación Bernardino Biella y el
      ingeniero José D. Guzmán como Vicegobernador.
    </p>
    <p>
      1 de mayo de 1965:&nbsp;Asumió como senador de la provincia de Salta, el
      médico Néstor Victorino Rodríguez y cesó en sus funciones el 28 de junio
      de 1966.
    </p>
    <p>
      1 de mayo de 1980:&nbsp;Inició la transmisión en color Canal 11 de Salta.
    </p>
    <p>
      2 de mayo de 1818:&nbsp;Se realizó en Salta, la elección del
      gobernador-intendente, surgido del reglamento provisorio de 1817, expedido
      por el Soberano Congreso.
    </p>
    <p>
      2 de mayo de 1896:&nbsp;Nació en Salta, Ernesto Francisco Bavio, quien se
      graduó de abogado en 1920. En 1922 fue electo concejal por el municipio de
      Salta-Capital, cuerpo del que fuera elegido presidente y en tal carácter
      ejerció interinamente las funciones de intendente municipal. En 1928 fue
      elegido diputado provincial y en 1929 asumió la presidencia de la Cámara
      Legislativa. Por imperio constitucional ejerció el mando gubernativo en
      ausencia del gobernador Julio Cornejo. Propugnó la explotación de
      Yacimineto Petrolíferos Fiscales totalmente por el Estado. Por efectos de
      la revolución militar el 6 de septiembre de 1930, cesó sus funciones
      legislativas. Detenido en Salta fue conducido a Buenos Aires y expatriado
      en 1931. En 1943 adhirió a la revolución del 4 de junio. Fue elegido
      presidente de la Asamblea Legislativa, que había de tomar juramento
      constitucional al electo presidente de la nación, Juan Domingo Perón. Fue
      embajador en Uruguay y presidió el partido justicialista.
    </p>
    <p>
      2 de mayo de 1969:&nbsp;Falleció el escultor Ernesto Soto Avendaño.
      Residió en Salta y Jujuy. Realizó la monumental obra que está en Humahuaca
      “el monumento de la Independencia”. En Tilcara, Jujuy, está el MUSEO/ Soto
      Avendaño, donde se exiben obras donadas por el autor a la provincia de
      Jujuy.
    </p>
    <p>
      3 de mayo de 1869:&nbsp;Nació en Salta, Manuel Anzoátegui. Se graduó de
      médico en 1894. Fue legislador provincial, presidente del Banco Provincial
      de Salta, intendente de la ciudad capital y ministro de hacienda en el
      gobierno del doctor Angel Zerda. Fue presidente de la Sociedad Rural
      Salteña y del club Gimnasia y Tiro. Falleció en Buenos Aires el 4 de
      febrero de 1920.
    </p>
    <p>
      3 de mayo de 1948:&nbsp;Monseñor Roberto José Tavella, tomó una de sus más
      trascendentales medidas: Fundó el Instituto de Humanidades de Salta. Fue
      la primera casa universitaria de la provincia.
    </p>
    <p>
      3 de mayo de 1956:&nbsp;Se creó en Salta, el Instituto del Bocio,
      organismo sanitario asistencial destinado al estudio de endemia, para
      poner en vigencia la ley de profilaxis y para realizar su contralor. El
      instituto se transformó posteriormente en Instituto de Endocrinología e
      inicia sus tareas en 1958. El que promulgó el decreto ley de creación fue
      el interventor federal interino, doctor Arturo Oñativia.
    </p>
    <p>
      3 de mayo de 1972:&nbsp;Murió en Salta, Carlos Márquez Valladares.
      Periodista y docente. Autor de ensayos, cuentos, novelas y poemas. En su
      Tucumán natal, colaboró en La Gaceta, El Orden. Fue secretario de la
      revista La Cumbre. Colaboró en El Hogar, Mundo Argentino y Leoplán. Autor
      de la comedia Broma de Carnaval y Juventud, Juventud. En 1936, fundó y
      dirigió la revista Tucumán. Fue interventor municipal en Rosario de Lerma,
      Salta, en 1943. En 1947 fundó la Universida Latinoamericana, que editó
      antologías líricas anuales. Publicó: Poetas de hispanoamérica (1948).
    </p>
    <p>
      3 de mayo de 1973:&nbsp;Asumió como senador de la provincia de Salta, el
      doctor Julio Fortunato Temer, cesando en sus funciones el 23 de noviembre
      de 1974.
    </p>
    <p>
      4 de mayo de 1817:&nbsp;El ejército español mandado por el general de la
      Serna, abandonó la ciudad de Salta, acosado continuamente por las milicias
      de Güemes a lo largo de nuestra geografía, llegando hasta la Quebrada de
      Humahuaca.
    </p>
    <p>
      4 de mayo de 1903:&nbsp;Comenzó la construcción de un avenida, en la
      prolongación de la calle Güemes, hacia la falda del cerro San Bernardo,
      proyecto del concejal Abraham Echazú. Esa avenida en la ciudad de Salta,
      llega hasta el Monumento a Güemes.
    </p>
    <p>
      4 de mayo de 1931:&nbsp;Nació en Capital Federal, Manuel Jorge López Sosa,
      que se recibió de médico en la Universidad de Buenos Aires en 1956. Se
      especializó en cardiología. Primero trabajó en hospitales de Capital
      Federal. Luego se radicó en Salta, donde fue Director del Hospital del
      Milagro y Subsecretario de Salud Pública de la provincia de Salta de 1968
      a 1969. Se desempeñó también en el Hospital Oñativia, miembro fundador de
      la Sociedad de Cardiología de Salta.
    </p>
    <p>
      4 de mayo de 1941: Nació en Orán, Fernando David Magadán. Contador Público
      Nacional que ocupó importantes cargos públicos. Uno de los responsables de
      Pro Cultura Salta que todos los años programa el Abril Cultural Salteño.
    </p>
    <p>
      4 de mayo de 1943:&nbsp;Nació en Embarcación, Salta, Jorge E. Llaya, quien
      se doctoró en Medicina y Cirugía. Miembro de la Academia Nacional de
      Cirugía. Por sus tareas literarias figura en antología de Orán.
    </p>
    <p>
      4 de mayo de 1945:&nbsp;Nació en Salta, Maggie Reyes. Artista plástica que
      ejerce la docencia en el profesorado de la Escuela Normal y en la Escuela
      de Bellas Artes. Profesora de los talleres Jaime Dávalos. Pi tora y
      escultora que desarrolla permanentemente actividades culturales. Es
      creadora de entidades culturales en defensa de la cultura popular y
      regional.
    </p>
    <p>
      4 de mayo de 1957:&nbsp;Nació en Salta, Luis D. Zavaleta. Abogado desde
      1982, egresado de la Universidad Católica de Salta. Dirigente de la Unión
      Cívica Radical, que fue candidato a gobernador de la provincia de Salta.
    </p>
    <p>
      5 de mayo de 1768:&nbsp;Nació en Salta, Pedro Antonio Arias de Velázquez.
      Se doctoró en leyes en la Universidad de San Marcos (Lima). Donó su
      biblioteca a los Padres Mercedarios. Producido el movimiento
      revolucionario de 1810, tomó parte activa de la divulgación del accionar
      patriota de la gobernación-intendencia de Salta (que abarcaba todo el
      actual noroeste argetino). Fue vocal de la junta provincial de gobierno en
      1811. Colaboró con Belgrano. Fue vocal de la junta durante el gobierno en
      1811. Colaboró con Belgrano. Fue ministro general durante el gobierno del
      general Güemes. Influyó en el entredicho surgido entre Rondeau y Güemes,
      para culminar con la paz de los Cerrillos. Luego perteneció a la oposición
      de Güemes. Se alejó de Salta y se radicó en Tucumán con su amigo el doctor
      Bernabé Aráoz. Regresó a Salta, luego se la muerte de Güemes. Colaboró con
      Arenales, Gorriti y el coronel Pablo Alemán.
    </p>
    <p>
      5 de mayo de 1817:&nbsp;El ejército realista al mando del general José de
      la Serna, que invadió el norte argentino para distraer o dificultar la
      organización del ejército que dirigía el general San Martín en Mendoza y
      evitar su ataque a Chile, se vió obligado a abandonar la ciudad de Salta,
      por la patriótica residencia de los gauchos de Güemes.
    </p>
    <p>
      5 de mayo de 1867:&nbsp;El general Aniceto Latorre fue derrotado por el
      general Francisco Centeno, en Ciénega del Bañado, provincia de Salta.
    </p>
    <p>
      5 de mayo de 1915:&nbsp;Nació en Santa Catalina, Jujuy, Lucía Rueda;
      Maestra rural. Estudió en su pueblo natal y en el colegio Santa Rosa de
      Salta. Publicó: Ecos de quena (1974).
    </p>
    <p>
      5 de mayo de 1932:&nbsp;Fue inaugurada en uno de los patios del Colegio
      Nacional, la cancha del Centro de Estudiantes Secundarios, que presidía el
      joven J. Medrano. Participaron tres equipos: Colegio Belgrano (Bracamonte,
      Román, Langou, Sosa, Villagrán), Estudiantes (Javier Alfaro, Caro, Julio
      Alfaro, Lacroix y Medrano); Artillería (Torino, Reyes, Biondi, Sammené y
      Galindez). Fue en origen del básquetbol de Salta.
    </p>
    <p>
      5 de mayo de 1965:&nbsp;Nació en Salta, Guillermo Romero Ismael. Estudió
      danza, malambo, canto y música y se diplomó en ciencias del folklore. A
      los 22 Superior de música lírica. Estudió canto en la Escuela Superior de
      música “José Lo Giúdice” de Salta, con la maestra Ana M. Alderete de
      Torino y más tarde completó sus estudios en el Instituto Superior de Arte
      del teatro Colón de Bunos Aires. En 1991 debutó en el Teatro Colón en un
      concierto con la orquesta filarmónica, bajo la dirección de Pedro I.
      Calderón. Actuó en Francia, Italia, España. Fue Premio Persona en 1991 en
      Salta. Logr´un 2º premio en Logroño, España, 1º Premio de Nápoles, Italia;
      3º Premio en Torroella de Montgrí, España, y ganó también el Premio Caja
      de Jaen, en España, que consiste en la realización de una grabación y su
      distribución por Europa.
    </p>
    <p>
      6 de mayo de 1815:&nbsp;El Cabildo recibió una petición del pueblo,
      congregado en las proximidades de la sala capitular, consistente en que se
      procediera a elegir gobernador. Ante la insistencia popular, el Cabildo
      dispuso que los ciudadanos congregados votaran, resultando electo “casi
      por una general votación el señor coronel don Martín Güemes”.
    </p>
    <p>
      6 de mayo de 1817:&nbsp;Tropas salteñas mandadas por Dionisio Puch, del
      ejército de Güemes, derrotaron a los realistas, en Los Sauces o La
      Caldera, provincia de Salta.
    </p>
    <p>
      6 de mayo de 1939:&nbsp;Nació en Salta, Roberto Adolfo Castro. Se graduó
      de abogado en la Universidad de Córdoba. Fue presidente del centro de
      estudiantes salteños residentes en Córdoba. Ejerció la docencia en la
      Universidad Católica de Salta, dictando Derecho Procesal y Derecho Penal y
      otras materias. Profesor titulas del profesorado Manuel Estrada.
      Catedrático del Instituto de antropología de la provincia, rector del
      Instituto Superior de Esudios de Salta. Como dirigente fue delegado
      titular del Club Atlético Rivadavia ante la Liga Salteña de Fútbol, vocal
      del Tribunal de Apelaciones de la Liga Salteña de Fútbol, vicepresidente
      de la Liga Salteña de Fútbol. Miembro de la sociedad dientífica de Salta.
      Presidente del Tribunal de Penas de la Asociación Salteña de Básquet,
      junto al doctor Caro Figueroa. Como hombre de la justicia fue presidente
      del Jury de enjuiciamiento de la provincia de Salta; Agente Fiscal Nº3 del
      distrito judicial del centro; Juez de instrucción de 2ª nominación;
      Miembro representante del Colegio de Abogados de la provincia de la
      comisión redactora del actual código de procedimiento penal de Salta
      (1976); Tesorero y vicepresidente del Colegio de Magistrados de la
      provincia de Salta; Vocal de la cámara de apelaciones del trabajo;
      presidente de la Corte de Justicia de la provincia de Salta; presidente
      del Tribunal Electoral de la provincia de Salta. Co-redactor de las
      reformas vigentes en el Código de procedimiento laboral de la provincia.
      Fue director general del Registro del estado civil y capacidad de las
      personas de la provincia de Salta; Asesor del Banco de Préstamos y
      Asistencia Social; Co-fundador de las Asociaciones de Prefesionales
      Universitarios de Salta; Diputado provincial; subsecretario general de la
      gobernación; vocal del Tribunal Electoral Federal. Publicó: Procedimiento
      penal mixto, en colaboración con el doctor Vázquez (cuatro tomos);
      artículos en “Doctrina Jurídica”; La Auditoria Médica Compartida, en
      colaboración con Francisco Genovese.
    </p>
    <p>
      6 de mayo de 1949:&nbsp;Nació en Metán, Lucio Walter Erazu. Poeta premiado
      en el certamen Alfonsina Storni y en la canción inédita folklórica que
      organizó el Circulo Médico. Publicó; Capricornio y otros poemas, con el
      prólogo de Carlos Alberto “Toti” Daher.
    </p>
    <p>
      7 de mayo de 1817:&nbsp;El comandante Bartolomé de la Corte, del ejército
      de Güemes, derrotó al ejército realista que mandaba el general José de la
      Serna, en La Viña, provincia de Salta.
    </p>
    <p>
      7 de mayo de 1921: Nació en Salta, César Fermín Perdiguero. Poeta,
      escritor, periodista. Publicó entre otros títulos: “Calisto Gauna”, “Cosas
      de la Salta de antes”, “Antología del Cerro San Bernardo”. Incansable
      trabajador de la Cultura popular. Trabajó hasta su muerte para enaltecer
      el folklore. Destacada actuación en radios, emisoras de Salta y reconocido
      animador de espectáculos folklóricos. Fue autor de muchos temas del
      cancionero argentino que fueron interpretados por artistas prestigiosos.
    </p>
    <p>
      7 de mayo de 1959:&nbsp;La provincia de Salta creo la Escuela Primaria
      Nosturna, que venía funcionado desde 1953, como turno anexo de la Escuela
      Belgrano. Su primer directora fue María E. Escudero de Moyano. En 1964 se
      pasa a llamar Corina Lona.
    </p>
    <p>
      8 de mayo de 1820:&nbsp;El general Juan Ramírez y Orozco partió de su
      cuartel general en Tupiza y sus fuerzas invadieron las intendencias de
      Salta penetrando divididas en tres columnas. De inmediato Güemes dispuso
      el hostigamiento de los invasores.
    </p>
    <p>
      8 de mayo de 1864: Se produjo en Salta el conato revolucionario de los
      Uriburu. El presidente de la legislatura provincial convocó a elecciones y
      el doctor Cleto Aguirre fue elegido gobernador hasta 1866.
    </p>
    <p>
      8 de mayo de 1904: Nació en Salta, Diego E. Zabaleta. Hijo de Diego Pedro
      Zabaleta y Mercedes Linares. Integró el Consejo de Administración en
      calidad de tesorero académico, del Círculo Médico de Salta.
    </p>
    <p>
      9 de mayo de 1800:&nbsp;Nació en Salta, Gregorio Beeche. Coleccionó libros
      y documentos que con el tiempo le darían renombre. Cónsul argentino en
      Chile. Murió en Valparaíso el 28 de enero de 1878. Su valiosa bilbioteca
      de 4.500 volúmenes fue adquirida por el gobierno de Chile y pasó a
      trasnformarse en la Biblioteca Nacional de Valparaíso, la que actualmente
      lleva su nombre.
    </p>
    <p>
      9 de mayo de 1852:&nbsp;La Legislatura Provincial, designó como gobernador
      titular de Salta a don Tomás Arias.
    </p>
    <p>
      9 de mayo de 1918:&nbsp;La provincia de Salta, fue intervenida, asumiendo
      el delegado federal, doctor Emilio Giménez Zapiola.
    </p>
    <p>
      10 de mayo de 1857:&nbsp;Murió Juan José Castellanos, nacido en Salta en
      1778. Se doctoró en Derechos Romanos y Canónicos y en Teología. Recibió el
      Orden Sagrado; Capellán del Ejército Patriota; Diputado Provincial por el
      departamento de Cerrillos; Vicario capitular del Obispado, provisor y
      gobernador Eclesiástico del Obispado.
    </p>
    <p>
      10 de mayo de 1864:&nbsp;Nació en Salta, Gregorio Vélez. Estudió en su
      provincia natal la primaria, en Santiago del Estero el secundario y en el
      Colegio Militar, de donde egresó en 1884 como subteniente de Artillería.
      En 1910 era general. El presidente Roque Sáenz Peña lo nombró ministro de
      guerra. Fue el creador de la Escuela de Aviación Militar en 1912. Retirado
      del servicio activo regresó a Salta. Cuando el teniente general federal
      José Félix Uriburu se hizo cargo del gobierno nacional, lo designó
      interventor federal de Salta. Ejerció el mandato desde el 22 de septiembre
      de 1930 hasta el 20 de junio de 1931, en cuyo transcurso procedió a
      inaugurar el monumento al general Martín Miguel de Güemes, el 20 de
      febrero de 1931, que contó con la visita de Uriburu en Salta. Se radicó en
      la Capital Federal, donde falleció el 29 de enero de 1949.
    </p>
    <p>
      10 de mayo de 1869:&nbsp;Murió en Salta, el doctor Juan Fowlis Gorostiaga.
      Nació en Salta en 1819. Se graduó de abogado en Chuquisaca en 1844. En
      Lima se caso con Micaela Calvimonte, dama de gran cultura. Alternó su
      profesión con su vocación literaria. Era un poeta festivo.
    </p>
    <p>
      10 de mayo de 1893:&nbsp;Asumió como gobernador de Salta, el doctor Delfín
      Leguizamón.
    </p>
    <p>
      10 de mayo de 1954: Nació en Salta, Santiago Javier Rodríguez. Docente de
      Artes Visuales, en la Escuela de Bellas Artes Tomás Cabrera, de la que
      llegó a ser director. Desde 1973 expone como pintor en muestras
      individuales y colectivas. Ilustró varios lobros de poesías, entre los que
      se destaca Amoralgos del poeta Antonio Vilariño.
    </p>
    <p>
      10 de mayo de 1975: Murió en Cerrillos, Salta, José Luis Alfonso Borelli,
      nacido en Salta en 1917. Designado Oficial Mayor del Ministerio de
      Hacienda de la Provincia. En 1949 fue electo Intendente de Cerrillos. Fue
      subsecretario de Economía, Finanzas y Obras Públicas de la Provincia,
      Secretario de la Cámara de Diputados, Interventor del Palacio Legislativo
      y Secretario General de la Gobernación, entre los cargos que ostentó.
    </p>
    <p>
      11 de mayo de 1893: Nació en Salta, Emilio Domingo Sylvester. En 1917 se
      recibió de Ingeniero Civil, en Buenos Aires. Trabajó en la construcción en
      la línea férrea de Añatuya (Santiago del Estero) y Perico (Jujuy). Fue
      cofundador de El Círculo. Realizo el primer raid aéreo entre Salta y
      Santiago del Estero el 10 de junio de 1924, con la máquina general Güemes.
      Fundó el Aero Club de Salta. Practicando vuelo de acrobacia, tuvo un
      accidente que le costó la vida el 17 de octubre de 1925.
    </p>
    <p>
      11 de mayo de 1923: Se fundó el Club Central Norte de Metán. La primera
      comisión directiva fue integrada por José Tobías, Tomás Burgos, Eudoro
      Altamiranda, Pedro Carabajal, Federico Toledo, Daniel Soto, Walter Kitzón,
      Francisco Montenegro, Teófilo Thames, Abel Ortíz y Enrique Campos.
    </p>
    <p>
      11 de mayo de 1945: En un local de los altos de la calles Buenos Aires
      primera cuadra, donde funcionaba la Bolsa de Comercio de Salta, se
      reunieon un grupo de contadores con la intención de dar cumplimiento al
      decreto ley Nº 5.103 del Poder Ejecutivo Nacional por el cual se reglaba
      el ejercicio de las profesiones de Ciencias Económicas y se ordenaba crear
      los Consejos Profesionales de cada provincia. Uno de los gestores fue el
      contador Pedro Alejandro Courtade, quien había nacido en Mendoza, estudió
      en Tucumán, para luego radicarse en Salta. Fue Presidente del Consejo
      entre 1955 y 1957.
    </p>
    <p>
      11 de mayo de 1972: Se creó la Universidad Nacional de Salta, por Ley Nº
      19.633. El Poder Ejecutivo Nacional aprobó sus estudios el 3 de abril de
      1973, año en que comenzó a funcionar, con 6 departamentos, 4 Institutos y
      sedes regionales en Orán, Tartagal y Metán-Rosario de la Frontera, esta
      última se clausuró a los tres años.
    </p>
    <p>
      12 de mayo de 1819:&nbsp;El comandante Luis Burela, con los gauchos
      salteños, derrotó a los realistas mandados por el general José Canterac,
      en Iruya, provincia de Salta.
    </p>
    <p>
      12 de mayo de 1945: Nació en Purmamarca, provincia de Jujuy, Domingo Ríos.
      Hace treinta años está radicado en Salta, donde desarrolla su actividad
      musical. Grabó tres álbunes. Realizó cuatro giras por Europa. Este
      quenista interpreta con instrumentos fabricados por él mismo.
    </p>
    <p>
      12 de mayo de 1956: El doctor Arturo Oñativia, se hizo cargo interinamente
      del gobierno de la provincia de Salta; hasta que llegó el doctor Alejandro
      Lastra, Interventor Federal desde el 16 de mayo de ese año.
    </p>
    <p>
      12 de mayo de 1994:&nbsp;La Academia Argentina de Letras, ofreció su
      primera sesión pública en la ciudad de Salta.
    </p>
    <p>
      13 de mayo de 1952: Nació en Embarcación, Rubén Alberto Cabana. Fue
      convencional constituyente en 1976, concejal municipal de General Güemes,
      diputado provincial. En la actualidad es Intendente reelecto de la Ciudad
      de General Güemes.
    </p>
    <p>
      13 de mayo de 1985:&nbsp;Se inauguró en la ciudad de Madrid, España, en la
      Plaza Ciudad de Salta, ubicada en el barrio de la Colina, un busto de
      martín Miguel de Güemes. Este lugar fue visitado tiempo después, por el
      presidente del Instituto Güemesiano de Salta, Licenciado Luis Oscar
      Colmenares.
    </p>
    <p>
      14 de mayo de 1817: El coronel Jorge Vidt, del ejército de Güemes, derrotó
      a los realistas, en Altos de la Quintana, provincia de Salta.
    </p>
    <p>
      14 de mayo de 1885: Era elegido Vicario Capitular para la Diócesis de
      Salta, el director del seminario diocesano, don Pablo Padilla y Bárcena.
      El 13 de junio lo reconocía el gobierno nacional.
    </p>
    <p>
      14 de mayo de 1916: Nació en Metán, Salta, José Antonio Saravia Toledo. Se
      recibió de abogado en 1957. Docente y secretario del colegio nacional de
      Salta. Secretario de la Corte de Justicia de Salta hasta su muerte. En
      1948 Los Puesteros de Yatasto. En 1958 Los Duendes. En 1973 Los Changos.
      Fue socio fundador del colegio de magistrados y funcionarios del Poder
      Judicial de Salta, en 1965. Murió en Salta, el 18 de noviembre de 1977.
    </p>
    <p>
      14 de mayo de 1924: Fue creada por Adolfo Güemes, la Biblioteca de la
      legislatura de Salta.
    </p>
    <p>
      14 de mayo de 1930:&nbsp;Nació en Salta, Juan Carlos Saravia. Es
      integrante fundador del conjunto salteño, Los Chalchaleros, que hace ya
      casi medio siglo, le canta a la patria. REcorrió el mundo con el mensaje
      poético y musical de su Salta natal.
    </p>
    <p>
      14 de mayo de 1946: Asumió como gobernador electo de Salta, el doctor
      Lucio Alfredo Cornejo.
    </p>
    <p>
      14 de mayo de 1975: Se fundó en Joaquín V. González, el Club Cultural,
      Social y Deportivo Doctor Joaquín V. González. Entre los fundadores
      estuvieron Miguel Sorayre, Pipo Porrati y Rodolfo Díaz Salem entre otros.
      Entre los integrantes de la primera comisión directiva Nena Pérez, Mario
      Biassutti, Carlos Pomo, José Coronel y otros. Este Club es el organizador
      del Festival del Quebracho. Sus equipos fueron varias veces campeones de
      fútbol, tenis y básquet. Su actual comisión directiva está conformada
      entre otras personas por Rodolfo Díaz Salem, Carlos Pomo, Olga de Pérez,
      Rubén Benitez.
    </p>
    <p>
      15 de mayo de 1816: Salta juró obediencia al Congreso de Tucumán y
      reconoció como nuevo director supremo a Pueyrredón, cumpliendo con lo que
      era la aspiración general y del mismo Güemes.
    </p>
    <p>
      15 de mayo de 1817:&nbsp;El comandante Juan Antonio Rojas, del Ejército de
      Güemes, derrotó a los realistas, en Altos de la Quintana, provincia de
      Salta.
    </p>
    <p>
      15 de mayo de 1953: Murió en Atocha, el poeta José Solis Pizarro. Nació en
      Salta en 1909. Alternó las tareas rurales con las letras. Su
      establecimiento rural que se llamaba El Refugio, fue rebautizado con el
      nombre de República Lírica de Atocha. En 1948 fue designado miembro de la
      Comisión Provincial de Difusión Tradicional y Folklórica. Fue distinguido
      como miembro de la Academia de Ciencias y Artes de Cádiz (España).
      Publicó: Tradicion Norteña (1929) y Atocha, tierra mía (1939). No alcanzó
      a puiblicar: Cruz de cenizas y Rincón nativo.
    </p>
    <p>
      16 de mayo de 1817: El doctor José Rehead, se dirigía desde Salta al
      comodoro inglés Bowles, diciéndole: “…Ud. no podrá creerlo, los enemigos
      de Güemes en Tucumán, crecen en proporción de los sacrificios que él hace
      para defenderlos”.
    </p>
    <p>
      17 de mayo de 1817: Las acciones cumplidas por Güemes hicieron que el
      Director Supremo Pueyrredón, le expediera los despachos de coronel mayor
      de los Ejércitos de la Patria, una medalla de plata con trazos de oro para
      los jefes, una puramente de plata para los oficiales y, para la tropa, un
      escudo de paño blanco con las letras celestes, exceptuando los sargentos y
      cabos que la deberán traer de hilo de oro, los primeros y de plata, los
      segundos. La estrella es de seis picos con el rostro de Apolo en el medio.
    </p>
    <p>
      18 de mayo de 1817:&nbsp;Nació en la Hacienda de Campo Santo, Juan
      Fernández Cornejo y Usandivaras. Estudió en la ciudad de Salta los
      primeros años. Desistió de estudios superiores y se dedicó a la conducción
      del ingenio azucarero San Isidro de Campo Santo. Allí permaneció su vida,
      en su ingenio, donde encontró la muerte el 11 de agosto de 1880.
    </p>
    <p>
      18 de mayo de 1820: Güemes pidió ayuda a provincias ubicadas al Sur de
      Salta. Bustos, gobernador de Córdoba, le envió 500 hombres, al mando del
      coronel Alejandro Heredia.
    </p>
    <p>
      18 de mayo de 1919:&nbsp;Nació en Salta, oscar Venacio Oñativia. Doctor en
      filosofía y letras y doctor en Psicología. Es autor de las siguientes
      obras: “Percepción y creatividad” (1977); Método integral (Lecto-escritura
      – 1986); Método integral (Matemática inicial – 1983 – con Yolanda Baffa
      Trasci); Lengua, ritmo y sentido (1976); Antropología de la Conducta
      (1978); Método integral (1992 – Brasil); Semiótica y Educación (1922 – Con
      Rodolfo G. Alurralde).
    </p>
    <p>
      19 de mayo de 1820:&nbsp;El comandante Dionisio Puch, del Ejército de
      Güemes, derrotó a los realistas, em Lomas de San Lorenzo, provincia de
      Salta.
    </p>
    <p>
      19 de mayo de 1820:&nbsp;La falta de recursos motiva que el Cabildo de
      Salta, a pedido de Güemes, dispongan en el término de 6 días se levante un
      emprésito forzoso de seis mil pesos.
    </p>
    <p>
      19 de mayo de 1905: Nació en Salta, Juan Agustín Avellaneda. Muy joven
      ingresó a Ferrocarriles del Estado, donde se jubiló. Fue presidente de la
      Unión Ferroviaria, Seccional Salta. Fue vicepresidente primero del Senado,
      donde ejerció la presidencia Provicional del Senado e interinamente la
      vicegobernación. En homenajea su honradez, una calle de Salta lleva su
      nombre.
    </p>
    <p>
      19 de mayo de 1961:&nbsp;Nació José Antonio Vilariño. En 1984 se recibio
      de Ingeniero Quimico en la Universidad Nacional de Salta. Fue docente en
      esa Universidad. Ocupó cargos públicos importantes llegando a ser Ministro
      de Bienestar Social. En la actualidad es Diputado provincial.
    </p>
    <p>
      20 de mayo de 1916:&nbsp;Los superiores de la Orden Franciscana,
      autorizaron la construcción del nuevo convento, en la ciudad de Salta.
    </p>
    <p>
      20 de mayo de 1935:&nbsp;Nació en la provincia de Salta, Dino Saluzzi.
      Destacado músico salteño, que ejecuta el bandoneón. Grabó con Los
      Chalchaleros y con Alicia Martínez. Realiza grabaciones y recitales en
      Europa, donde se ha ganado un alto respeto.
    </p>
    <p>
      20 de mayo de 1975:&nbsp;El gobierno de la provincia de Salta aprobó la
      modificación de los Estatutos de la Universidad Católica de Salta y
      Monseñor Pérez designó rector al prebístero Normando J. Requena y delegado
      Arzobispal a Monseñor Raúl A. Casado.
    </p>
    <p>
      21 de mayo de 1748:&nbsp;Nació en Abionzo, Valle de Carriedo, Santander,
      España, el padre de Martín Miguel de Güemes, don Gabriel de Güemes
      Montero. Sirvió en la tesorería de la guerra de Madrid desde 1770, hasta
      el 1º de abril de 1777. Las inclinaciones económicas las heredó de su
      padre don Manuel de Güemes, que se desempeñó como tesorero del Monte Pío
      del ministerio de dentro y fuera de la Corte.
    </p>
    <p>
      21 de mayo de 1777:&nbsp;Gabriel de Güemes Montero celebró en Cádiz,
      España, su último cumpleaños en el continente europeo. Cumplió 29 años.
      Luego vivió, hasta su muerte, ocurrida 30 años después, en las actuales
      provincias de Salta y Jujuy. Padre de Martín Miguel de Güemes.
    </p>
    <p>
      21 de mayo de 1963: Falleció el primer Arzobispo de Salta, Monseñor
      Roberto José Tavella.
    </p>
    <p>
      22 de mayo de 1701:&nbsp;El gobierno civil del Tucumán se estableció con
      sede en la ciudad de Salta, transformándose así, en capital reconocida la
      que durante el gobierno de don Gutiérrez de Acosta y Padilla lo fuera en
      forma transitoria.
    </p>
    <p>
      22 de mayo de 1870:&nbsp;Falleció en Orán, el general Vicente de Uriburu,
      guerrero de la Independencia. Nació en Salta en 1796 y fue bautizado el 20
      de julio de 1801. Muy joven se incorporó al Ejército Auxiliar a las
      órdenes de Belgrano. Por su actuación en la Batalla de Salta fue ascendido
      a alférez. Colaboró a organizar Los Infernales al mando de Güemes. Fue
      herido en el combate de Huacalera. En 1825 se retiró del Ejército con el
      grado de general. En 1831 asumió en carácter temporal el mando de la
      provincia de Salta. Radicado en Orán, fue nombrado ppor dos períodos
      teniente gobernador. Sus restos recibieron solemne sepultura en la Iglesia
      Matriz de Orán.
    </p>
    <p>
      22 de mayo de 1912: Nació el doctor Ramón Jorge. Se recibió de médico en
      1936. Fue director general de sanidad en 1948. Fue director del Hospital
      San Bernardo. Fue secretario de Educación y Cultura. Murió el 15 de
      octubre de 1988.
    </p>
    <p>
      22 de mayo de 1992: Se creó la Juntas de Estudios Históricos de Metán, por
      iniciativa del Intendente Roberto Gramaglia.
    </p>
    <p>
      23 de mayo de 1913:&nbsp;El intendente de Salta, Agustín Usandivaras
      inauguró el servicio de trenes eléctricos en Salta. Alguien soñó alguna
      vez extenderlo hasta la cumbre del Cerro San Bernardo. Este servicio
      funcionó hasta fines de diciembre de 1935.
    </p>
    <p>
      23 de mayo de 1951: Nació en Salta, Oscar O. Rodríguez. Es director del
      Ballet Patria Argentina e integrante del Dúo Los Salamanqueros. Director
      del Cuerpo de Danza Municipal “Ciudad de Salta”. Delegado del Festival
      Nacional del Malambo de Laborde, de la provincia de Córdoba.
    </p>
    <p>
      24 de mayo de 1821:&nbsp;La mayoría de los miembros del Cabildo de Salta,
      se pronunciaron contra Güemes declarando que ha cesado en el cargo de
      gobernador de la Intendencia. Güemes se encuentra en el Sur de la
      provincia reuniendo fuerzas para enfrentar al gobernador de Tucumán, don
      Bernabé Aráoz.
    </p>
    <p>
      24 de mayo de 1856: Murió en San Carlos provincia de Salta, Santiago
      Morales, quien había nacido en Chicoana. Aportó dinero y realizó préstamos
      para el mantenimiento de las guerras de la Independencia. Actuó en forma
      notoria en Puesto del Marqués (17 de octubre de 1913) y en la defensa de
      Chicoana, el 24 de abril de 1817. Obtuvo ascensos por méritos de guerra.
    </p>
    <p>
      24 de mayo de 1972:&nbsp;Se creó la Escuela de Música de la provincia de
      Salta, la que comenzó a funcionar en el edificio de la Escuela Martín
      Miguel de Güemes.
    </p>
    <p>
      25 de mayo de 1770: Nació en la ciudad de Salta, don Manuel Antonio de
      Acevedo y Torino. Sus padres: don José Manuel Acevedo y González, Oidor
      del Cabildo local y doña María Juana Torino de Viana, descendiente de
      tradicionales familias salteñas. Estudió en el colegio Monserrat de
      Córdoba y luego en la Universidad de la misma ciudad, donde se graduó de
      abogado en 1793. Luego realizó Estudios Teológicos y alcanzó la Ordenación
      Sacerdotal dos años después. Se radicó en Tucumán, donde desempeñó
      funciones eclesiásticas al lado del Obispo Angel Mariano Moscoso. Fundó la
      Escuela de Filosofía en Salta de la que fue rector. Abrazó la causa
      patriótica, incorporándose poco después al ejército auxiliar del norte, al
      mando del general Belgrano. En Belén, Catamarca, ejerció el Ministerio
      Sacerdotal por muchos años. Fue elegido diputado por Catamarca para el
      Congreso de Tucumán de 1816. Fue el primer argentino que visitó la Santa
      Sede, en representación oficial de la Nueva Nación, ocasión en la que dejó
      establecidas las Relaciones Diplomáticas entre nuestro país y el Estado
      Pontífico. Mientras desempeñaba las funciones de congresista, lo
      sorprendió la muerte en la ciudad de Buenos Aires, el 9 de octubre de
      1825.
    </p>
    <p>
      25 de mayo de 1819: Güemes juró la Constitución unitaria de 1819, junto
      con los miembros del Cabildo de Salta.
    </p>
    <p>
      25 de mayo de 1842:&nbsp;Falleció en Sucre, el doctor Juan Ignacio de
      Gorriti. Nació en Horcones (Salta) en junio de 1766. Se docotoró en
      teología en la universidad de Charcas (Chuquisaca), Bolivia, luego se
      ordenó de sacerdote de manos del Obispo Monseñor Angel Mariano Moscoso.
      Atendió en sus comienzos las parroquias de Cochinoca y Casabindo y
      posteriormente la iglesia matriz de Jujuy. Adhirió a la revolución de
      1810. Fue designado diputado ante la junta de Buenos Aires. Siendo
      secretario de la Junta Grande, se opuso a la creación del Triunvirato, por
      lo que renunció y regresó a Jujuy, donde fue designado Vicario Foráneo por
      el Obispo de Salta. El 25 de mayo de 1812 bendijó la bandera en la iglesia
      matriz de Jujuy. Ayudó y acompañó a Belgrano en el Éxodo. Luego de la
      Batalla de Salta, se radicóen esta ciudad, donde fue designado, en 1815,
      regidor del cabildo y capellán del ejército del Norte. Fue diputado por
      Jujuy en la Junta permanente de Buenos Aires, donde recibió la noticia de
      la muerte de Güemes. En 1828 fue nombrado gobernador eclesiástico del
      obispado. Fue gobernador de la provincia desde el 1º de marzo de 1829
      hasta el 21 de diciembre de 1830. Primer gobernante perteneciente al
      clero. A consecuencia de las luchas internas (unitarios – federales) se
      estableció en Tupiza, Bolivia, de allí pasó a Cochabamba, donde asistió
      antes de morir a su hermano al general doctor José Ignacio de Gorriti. En
      Bolivia escribió: Memorias y Autobiografía.
    </p>
    <p>
      25 de mayo de 1940: Apareció en Metán la revista El Crestón. Años más
      tarde se transformará en Semanario. Lo dirigía Nicolás Moschetti y su
      segunda época, su hija Susana N. Moschetti. Salió casi 40 años.
    </p>
    <p>
      25 de mayo de 1960: Nació en San Carlos, provincia de Salta, Santiago
      Francisco Cruz. Artesano de tapices. Realizó exposiciones colectivas e
      individuales, en todo el país y en el extranjero. Docente en la Escuela de
      Bellas Artes y en los Talleres Artísticos Jaime Dávalos, ambos de la
      ciudad de Salta.
    </p>
    <p>
      25 de mayo de 1964: Se realizó el Acta de Constitución de la Biblioteca
      Almirante Guillermo Brown de villa Cristina de la ciudad de Salta,
      figurando como primer presidente el señor êdro Choque. Esta biblioteca
      luego pasó a denominarse Juan Carlos Dávalos.
    </p>
    <p>
      25 de mayo de 1972:&nbsp;Se creó el MUSEO/ Regional de Orán, en la
      provincia de Salta.
    </p>
    <p>
      25 de mayo de 1973: Asumió como gobernador electo de Salta,el doctor
      Miguel Ragone.
    </p>
    <p>
      26 de mayo de 1859:&nbsp;Se da como fecha de fundación de Metán, por ser
      el día en que la Curia acepta la donación de don Guillermo Sierra. No hubo
      acto fundacional.
    </p>
    <p>
      27 de mayo de 1864:&nbsp;El mayor Emilio Alfaro, derrotó a
      losrevolucionarios Latorre, Castro, Cornejo y Boedo en Salta.
    </p>
    <p>
      27 de mayo de 1867: Falleció en Salta, el doctor José Benjamín Dávalos de
      Molina. Nació en Salta en 1817. En Cochabamba se doctoró en 1844, en
      Derecho Romanoy Derecho Canónigo. Se dedicó a su profesión, al periodismo
      y a la docencia. Adhirió a la política de Urquiza e ingresó a las milicias
      hasta alcanzar el grado de ayudante mayor del regimiento Nº 8 de guardias
      nacionales, en 1861. En 1863 resultó electo diputado provincial, al cabo
      de ese mandato fue ascendido a teniente coronel de las milicias
      provinciales. Fue elegido gobernador de Salta desde el 3 de agosto de 1866
      hasta su muerte. Fueel abuelo de Juan Carlos Dávalos.
    </p>
    <p>
      27 de mayo de 1944: Se fundó el Colegio San Cayetaño de Vaqueros en la
      provincia de Salta.
    </p>
    <p>
      27 de mayo de 1972: Se efectuó la primera colación de grados de la
      Universidad Católica de Salta. La ceremonia presidida por el Arzobispo de
      Salta, Monseñor Pérez y la presencia de autoridades civiles, militares y
      eclesiásticas. 78 profesionales egresaron de las distintas carreras que se
      dictaban desde 1967, año en que la Universidad empezó a funcionar
      regularmente.
    </p>
    <p>
      27 de mayo de 1996: Fue inaugurado el Bachillerato Regional con
      Orientación en Producción, en Payogasta, departamento de Cahi, por
      resolución del Ministro de Educación, por gestión del vicegobernador
      Walter Wayar. Cuenta con albergue para los alumnos que llegan de parajes
      distantes. El título que se otorga luego de cinco años de estudio, es de
      Bachiller Regional con orientación en: Forestación rural y urbana;
      Producción de Bienes y Servicios Regionales Agro-Industriales; y
      Agricultura, que son optativos.
    </p>
    <p>
      28 de mayo de 1973: Asumió como presidente del Instituto rovincial de
      Seguros de Salta, el doctor Leiva Guestrin.
    </p>
    <p>
      29 de mayo de 1821:&nbsp;Güemes retornó a la ciudad de Salta y fue
      aclamado por la mayoría de los salteños. Los revolucionarios, que
      pretendían derrocarlo, se ocultaron o huyeron hacia el norte, llegando
      algunos hasta el cuartel general de Oñaleta, jefe realista.
    </p>
    <p>
      30 de mayo de 1797: Nació en San Carlos, Salta, Fernando F. de Córdoba.
      Luchó en el Escuadrón de Gauchos del Valle. Su producción literaria fue
      recopilada por su hijoAnastacio, quien la publicó con el título de
      “Cantares”. Falleció el 10 de agosto de 1846.
    </p>
    <p>
      30 de mayo de 1821: El general Martín Miguel de Güemes, derrotó a sus
      enemigos provincianos, en Castañares, Salta.
    </p>
    <p>
      30 de mayo de 1842: Nació en Salta, Manuel Peña de la Corte, condiscípulo
      de Victorino de la Plaza. Doctor en Jurisprudencia. En 1877 publicó
      “Güemes ante la opinión nacional”, en colaboración con el general Mitre.
      “epítome histórico de los guerreros y jornadas del Paraguay”. Murió en
      Buenos Aires, el 2 de julio de 1910.
    </p>
    <p>
      30 de mayo de 1922: Nació en El Galpón, provincia de Salta, Fernando
      Rufino Figueroa. Escritor que publicó entre otros títulos: “La mujer de
      piedra”, “Diccionario Biográfico de salteños”, “Sucedidos”, “Historia de
      Salta”, “El Tesoro del Curu-curu”, “Reflejos”, “Salteñismos”, “Don Martín”
      (novela), y otros títulos. Incansable trabajador de la cultura.&nbsp;
      <br />
      30 de mayo de 1946: Nació en Salta, Alfredo Enrique Kike Martínez.
      Periodista que se desempeña en la Dirección de Prensa de la Municipalidad
      de la Ciudad de Salta. Sonidista y animador de los Corsos Oficiales del
      Carnaval Salteño desde hace más de 25 años.
    </p>
    <p>
      31 de mayo de 1820:&nbsp;Una columna del ejército español, al mando del
      general José de la Serna derrotó a los patriotas y ocupó nuevamente la
      ciudad de Salta.
    </p>
    <p>
      31 de mayo de 1826:&nbsp;Murió en Salta, Eustaquio Moldes. Alistóse en el
      ejército patriota. Al infundirse en la retaguardia enemiga fue herido,
      perdiendo la mano y parte del brazo derecho. Acusado de revolucionario,
      Arenales lo mandó apresar y al resistirse fue muerto frente a su
      domicilio.
    </p>
    <p>
      31 de mayo de 1831:&nbsp;El coronel general Felipe Figueroa derrotó a los
      tropas salteñas unitarias que mandaba el general José Ignacio Gorriti, en
      Andalatá, provincia de Catamarca.
    </p>
    <p>
      31 de mayo de 1854:&nbsp;Murió en Salta, Patricio Corbalán Ibarguren.
      Formó parte de las fuerzas patriotas de Güemes y de Belgrano.
    </p>
    <p>
      31 de mayo de 1945: Nació en Córdoba, Pedro Máximo de los Ríos. Empresario
      minero de 1960 a 1980. Intendente de San Antonio de los Cobres de 1981 a
      1982. Senador provincial de 1983 a 1987, Vicegobernador de 1987 a 1990 y
      en la actualidad es senador provincial por el Departamento de Los Andes.
    </p>
    <p>
      31 de mayo de 1947:&nbsp;Se autorizó el funcionamiento de la ruletaen el
      Hotel de las Termas de Rosario de la Frontera, provincia de Salta.
    </p>
    <hr />
    <CommentsFB></CommentsFB>
  </Layout>
)

export default EfeEnero
